export default [
  'Ambassadors',
  'Customer Service',
  'Old Gift Card',
  'Marketing - Acquistion',
  'Marketing - Retention',
  'Referral Scheme',
  'Staff Credit',
  'Corporate Customer',
]
