import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import Router from 'next/router'
import React, { Component } from 'react'

import { Box, Button, Divider, Drawer, Hidden, IconButton, List, Typography } from '@material-ui/core'
import { AccountBalance, AccountCircle, Assignment, Bookmark, Chat, Dashboard, Description, Dns, ExitToApp, LocalHospital, Loyalty, Map, Menu, Person, PersonAdd, Receipt, Redeem, SupervisedUserCircle, Settings } from '@material-ui/icons'

import { colors } from '../../theme'
import { ResponsiveDrawerFooter, ResponsiveDrawerItem } from './'
import packageJSON from '../../package.json'

const version = `v${packageJSON.version}`

interface Props {
  store?: any
  container?: Element
  children?: any
  index: number
}

@inject('store')
@observer
class ResponsiveDrawer extends Component<Props> {
  @observable isOpened = false
  @observable mainRoute = '/'
  @observable items = [
    { section: 0, title: 'Dashboard', icon: <Dashboard />, route: '/', selected: false },
    { section: 0, title: 'Applications', icon: <Assignment />, route: '/applications', selected: false },
    { section: 0, title: 'Bookings', icon: <Bookmark />, route: '/bookings', selected: false },
    { section: 0, title: 'Professionals', icon: <AccountCircle />, route: '/professionals', selected: false },
    { section: 0, title: 'Customers', icon: <SupervisedUserCircle />, route: '/customers', selected: false },
    { section: 0, title: 'Categories', icon: <Dns />, route: '/categories', selected: false },
    { section: 0, title: 'Treatments', icon: <LocalHospital />, route: '/treatments', selected: false },
    { section: 0, title: 'Postcodes', icon: <Map />, route: '/postcodes', selected: false },
    { section: 0, title: 'Promo codes', icon: <Loyalty />, route: '/promoCodes', selected: false },
    { section: 0, title: 'Gift cards', icon: <Redeem />, route: '/giftCards', selected: false },
    { section: 0, title: 'Transactions', icon: <Receipt />, route: '/transactions', selected: false },
    { section: 0, title: 'Pages', icon: <Description />, route: '/pages', selected: false },
    { section: 0, title: 'Payouts', icon: <AccountBalance />, route: '/payouts', selected: false },
    { section: 0, title: 'In App Messages', icon: <Chat />, route: '/inAppMessages', selected: false },
    { section: 0, title: 'Settings', icon: <Settings />, route: '/settings', selected: false },
    { section: 1, title: 'Loading...', icon: <Person />, route: 'user', selected: false },
    { section: 1, title: 'Admins', icon: <PersonAdd />, route: '/admins', selected: false },
    { section: 1, title: 'Logout', icon: <ExitToApp />, route: 'logout', selected: false },
  ]

  constructor(props: Props) {
    super(props)
    // Init first main route
    this.mainRoute = '/' + Router.route.split('/')[1]

    // On mobile when route changes close the drawer
    Router.events.on('routeChangeStart', url => {
      this.isOpened = false
      this.mainRoute = '/' + url.split('?')[0].split('/')[1]
    })
  }

  // Open drawer
  handleDrawerToggle = () => {
    this.isOpened = !this.isOpened
  }

  // On index change
  onItemClick = (index: number) => {
    // Handle logout
    if (this.items[index].route === 'logout') {
      const { authStore, uiStore } = this.props.store
      authStore.logOut()
      uiStore.showLoginModal()
      return
    }

    // Handle route
    Router.push(this.items[index].route)
  }

  render() {
    const { store: { authStore, config }, container } = this.props
    const { user } = authStore
    const { firstName, lastName } = user

    const drawer = (
      <List className='drawer'>
        <div>
          {this.items.map((item, index) => {
            const isDifferentSection = index !== 0 && this.items[index - 1].section !== item.section

            // Handle footer where first element is account name
            if (isDifferentSection) {
              item.title = firstName ? `${firstName} ${lastName}` : 'Loading...'
              return <Box key={index} mt={2}>
                <Divider style={{ backgroundColor: colors.LINE }} />
                <Box mt={2}>
                  <ResponsiveDrawerItem item={item} index={index} selected={this.mainRoute === item.route} disabled />
                </Box>
              </Box>
            }

            // Display item
            return <ResponsiveDrawerItem key={index} item={item} index={index} selected={this.mainRoute === item.route} onPress={this.onItemClick} />
          })}
        </div>

        {config.name !== 'production' && <Box pt={4} display='flex' flexDirection='column' alignItems='center'>
          <Button variant={'text'} color={'primary'} onClick={authStore.expireToken}>{'Expire Auth Token'}</Button>
          <Typography variant='overline'>{`${version} ${config.name.substring(0, 3)}`}</Typography>
        </Box>}

        <ResponsiveDrawerFooter />
      </List>
    )

    return (
      <div>
        <Hidden smUp implementation='css'>
          <IconButton color='inherit' onClick={this.handleDrawerToggle}>
            <Menu />
          </IconButton>
        </Hidden>

        <nav>
          <Hidden smUp implementation='css'>
            <Drawer
              container={container}
              variant='temporary'
              open={this.isOpened}
              onClose={this.handleDrawerToggle}
              ModalProps={{ keepMounted: true }}>
              {drawer}
            </Drawer>
          </Hidden>

          <Hidden xsDown implementation='css'>
            <IconButton color='inherit' onClick={this.handleDrawerToggle} className='icon-button'>
              <Menu />
            </IconButton>
            <Drawer variant='temporary' onClose={this.handleDrawerToggle}>
              {drawer}
            </Drawer>
          </Hidden>
        </nav>

        {styles}
      </div >
    )
  }
}

const styles = (
  <style> {`
    .drawer {
      display: flex;
      flex-direction: column;
      height: 100vh;
      justify-content: space-between;
      margin-top: 20px;
    }
    .icon-button {
      position: fixed;
      border-radius: 25px;
      background-color: white;
      margin: 26px 10px 0px;
      padding: 8px;
      box-shadow: 0px 3px 8px 0px rgb(194 182 222 / 20%);
    }
  ` }</style>
)

export default ResponsiveDrawer
