import { action } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'

import MomentUtils from '@date-io/moment'
import { Box, Typography } from '@material-ui/core'
import { DatePicker as Picker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

interface Props {
  property: string
  value: any
  label: string
  disabled?: boolean
  errorText?: string
  componentProps?: any
  showTime?: boolean
  disablePast?: boolean
  autoOk?: boolean
  onChange: (property: string, value: any) => void
}

@observer
class DatePicker extends Component<Props> {
  @action.bound onChange = (value: any): void => {
    const { property, onChange } = this.props
    onChange(property, value)
  }

  render() {
    const { value, label, errorText = '', property, componentProps, disabled, showTime = false, disablePast = true, autoOk = false } = this.props
    const error = errorText.length > 0
    const PickerComponent = showTime ? DateTimePicker : Picker
    const specificProps = showTime ? { minutesStep: 15, format: 'D MMM YYYY, HH:mm z' } : { format: 'Do MMM YYYY' }

    return (
      <>
        <Typography variant={'body2'} style={{ fontSize: 14 }}>{label}</Typography>

        <Box mt={0.7} />

        <MuiPickersUtilsProvider utils={MomentUtils}>
          <PickerComponent
            autoOk={autoOk}
            value={value}
            disabled={disabled}
            onChange={this.onChange}
            inputVariant='standard'
            name={property}
            margin='normal'
            fullWidth
            clearable
            error={error}
            helperText={errorText}
            variant='dialog'
            disablePast={disablePast}
            InputProps={{
              disableUnderline: true,
            }}
            {...specificProps}
            {...componentProps} />
        </MuiPickersUtilsProvider>
      </>
    )
  }
}

export default DatePicker
