import { action } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'

import { Box, FormControl, TextField, Typography, TextFieldProps, InputProps } from '@material-ui/core'
import { snakeCase } from 'voca'

interface Props {
  property: string
  value: any
  label?: string
  type?: string
  step?: number
  errorText?: string
  componentProps?: TextFieldProps
  disabled?: boolean
  className?: any
  placeholder?: string
  multiline?: boolean
  rows?: number
  borderRadius?: number
  autocomplete?: string
  centerText?: boolean
  inputProps?: Record<string, any>
  InputProps?: Partial<InputProps>
  onSubmit?: () => void
  onChange?: (property: string, value: any) => void
  ariaPrefix?: string
}

@observer
class FormTextField extends Component<Props> {
  @action.bound onChange = (event: any): void => {
    const {
      property,
      onChange = () => { },
      type = 'text',
      step = 0,
      disabled = false,
    } = this.props

    if (disabled) return

    if (type === 'number') {
      let value = 0
      if (event.target.value !== '') {
        value = step > 0 ? parseFloat(event.target.value) : parseInt(event.target.value, 10)
      }
      property && onChange(property, value)
    } else if (type === 'float') {
      if (RegExp(/^\d+(\.\d{0,2})?$/).test(event.target.value) || event.target.value.length === 0) {
        property && onChange(property, event.target.value)
      }
    } else {
      property && onChange(property, event.target.value)
    }
  }

  @action.bound onBlur = (_event: any): void => {
    const { property, onChange, value, type } = this.props
    if (type === 'number' && (isNaN(value) || value === '' || value === undefined || value === null) && onChange !== undefined) {
      property && onChange(property, 0)
    }
  }

  @action.bound onKeyPress = (event: any) => {
    const { onSubmit } = this.props

    if (event.key === 'Enter' && onSubmit) {
      onSubmit()
    }
  }

  render() {
    const {
      value,
      property,
      label,
      errorText = '',
      componentProps = {},
      type = 'text',
      disabled = false,
      placeholder = '',
      className,
      multiline = false,
      borderRadius = 10,
      rows,
      autocomplete = '',
      inputProps = {},
      ariaPrefix = '',
      InputProps,
    } = this.props

    const error = errorText.length > 0
    const fieldType = type === 'float' ? 'number' : type
    const autocompleteProps = autocomplete ? {} : {
      autoComplete: 'new-password',
      form: {
        autoComplete: 'off',
      },
    }

    return <Box mb={2} width={'100%'}>
      <FormControl fullWidth>
        {!!label && <Box mb={1}>
          <Typography variant={'body1'} color={'textSecondary'}>
            {label}
          </Typography>
        </Box>}

        <TextField
          key={label + property}
          type={fieldType}
          className={className}
          margin={'normal'}
          autoComplete={autocomplete}
          variant={'standard'}
          fullWidth
          error={error}
          helperText={errorText}
          value={value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          disabled={disabled}
          placeholder={placeholder}
          multiline={multiline}
          rows={rows}
          InputProps={{
            style: { minHeight: 50, padding: 9, paddingLeft: 12, paddingRight: 12, borderRadius },
            disableUnderline: true,
            ...InputProps,
          }}
          onKeyPress={this.onKeyPress}
          inputProps={{
            ...autocompleteProps,
            'aria-label': snakeCase(`${label || property} ${ariaPrefix}`),
            ...inputProps,
          }}
          {...componentProps}
        />
      </FormControl>
    </Box>
  }
}

export default FormTextField
