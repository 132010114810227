import { InMemoryCache } from '@apollo/client/cache'
import { ApolloClient, from } from '@apollo/client/core'

import createClientLinks from './createClientLinks'
import { $RootStore } from '../../src/stores/RootStore'
import { Sdk, getSdkApollo } from './requester'

export interface $CommunicationConfig {
  apiHost: string
  wsHost?: string
  timezone: string
  platform: string
  env: string
  addNetworkLatency: number
  deviceip?: string
}

class Communication {
  rootStore: $RootStore
  client: ApolloClient<any>
  wsLink: any
  config: $CommunicationConfig
  latestErrorCode: string | undefined
  requester: Sdk

  constructor(rootStore: $RootStore, config: $CommunicationConfig) {
    this.rootStore = rootStore

    const { links, wsLink } = createClientLinks(rootStore, config)
    this.config = config
    this.wsLink = wsLink

    this.client = new ApolloClient({
      link: from(links),
      cache: new InMemoryCache({
        dataIdFromObject: (object: any) => object._id ? `${object.__typename}_${object._id}` : '',
      }),
      defaultOptions: {
        query: { fetchPolicy: 'network-only' },
      },
    })

    this.requester = getSdkApollo(
      this.client,
      {
        dev: config.env === 'development',
        manageError: this.rootStore.errorHandler.manageRawError,
        rootStore: this.rootStore,
        addNetworkLatency: this.config.addNetworkLatency,
      },
    )
  }

  closeWSLink = () => {
    this.wsLink.subscriptionClient.close()
  }

  connectWSLink = () => {
    this.wsLink.subscriptionClient.connect()
  }
}

export default Communication
