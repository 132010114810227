interface $Args {
  apiDefault: string
  platform: string
  appVersion: string
  useSecure: string
  override?: string
}

export default async function ({
  apiDefault,
  platform,
  appVersion,
  useSecure,
  override,
}: $Args) {
  let useApi = override

  if (!useApi) {
    const response = await fetch(`http${useSecure}://${apiDefault}/getApiVersion?platform=${platform}&appVersion=${appVersion}`)
    const { apiUrl } = await response.json()

    useApi = apiUrl === 'default' ? apiDefault : apiUrl
  }

  return {
    apiHost: `http${useSecure}://${useApi}/graphql`,
    wsHost: `ws${useSecure}://${useApi}/graphql`,
  }
}