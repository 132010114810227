import React, { Component } from 'react'
import { MenuItem, TextField, Typography, Box } from '@material-ui/core'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { colors } from '../../theme'

interface Props {
  property: string
  value: any
  label?: string
  placeholder?: string
  errorText?: string
  options?: Array<any>
  componentProps?: any
  disabled?: boolean
  getOptionLabel?: (value: any) => void
  onChange: (property: string, value: any) => void
  addValue?: boolean
}

@observer
class FormSelect extends Component<Props> {
  @action.bound onChange = (event: any): void => {
    const { property, onChange } = this.props
    const value = event.target.value
    onChange(property, value)
  }

  getOptionLabel(value: any) {
    const { getOptionLabel } = this.props
    return getOptionLabel ? getOptionLabel(value) : value
  }

  render() {
    const { value, property, errorText = '', label, placeholder = '', options = [], componentProps = {}, disabled, addValue = false } = this.props

    const error = errorText.length > 0

    const showOptions = [...options, ...(!!addValue ? [value] : [])]

    return (
      <>
        <Typography variant={'body2'} style={{ fontSize: 14 }}>{label}</Typography>

        <Box mt={0.7} />

        <TextField
          name={property}
          margin={'normal'}
          autoComplete={'off'}
          variant={'standard'}
          fullWidth
          value={value}
          select
          disabled={disabled}
          error={error}
          helperText={errorText}
          placeholder={placeholder}
          onChange={this.onChange}
          InputProps={{
            disableUnderline: true,
          }}
          {...componentProps}
        >
          {showOptions.map((o: any) => (
            <MenuItem key={o} value={o} style={{ color: colors.INK900 }}>
              {this.getOptionLabel(o)}
            </MenuItem>
          ))}
        </TextField>
      </>
    )
  }
}

export default FormSelect
