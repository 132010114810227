import 'intl'
import 'intl/locale-data/jsonp/en'

export const penceToPounds = (pence: number): number => pence / 100
export const poundsToPence = (pounds: number): number => pounds * 100
export const currencyFormat = (
  balance: number,
  fractionDigits = 2,
  pennies = true,
  locale = 'en-GB',
  currency = 'GBP',
) => new Intl.NumberFormat(locale, {
  style: 'currency',
  currency,
  maximumFractionDigits: fractionDigits,
  minimumFractionDigits: fractionDigits,
})
  .format(pennies ? penceToPounds(balance) : balance)
export const formatCurrencyForStats = (amount: number) => {
  const pounds = penceToPounds(amount)

  if (pounds >= 1000) {
    if ((pounds / 1000) % 1 === 0) return `£${pounds / 1000}k`

    return `£${(pounds / 1000).toFixed(1)}k`
  }

  return `£${pounds.toFixed(0)}`
}

export const currencySymbol = (locale = 'en-GB', currency = 'GBP') => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })

  if (!formatter.formatToParts) {
    // https://github.com/facebook/hermes/issues/1188
    // Not currently supported in Hermes on iOS
    return '£'
  }

  let symbol
  formatter.formatToParts(0).forEach(({ type, value }) => {
    if (type === 'currency') {
      symbol = value
    }
  })

  return symbol
}